import { createContext, FC, useReducer } from "react";
import { initialValue, reducer } from "../reducers/resultReducer";
import { TResultCtx } from "../types/contexts";

export const ResultCtx = createContext<TResultCtx>(undefined);
const ResultCtxProvider: FC = ({ children }) => {
  const [result, dispatch] = useReducer(reducer, initialValue);

  return (
    <ResultCtx.Provider value={{ result, dispatch }}>
      {children}
    </ResultCtx.Provider>
  );
};

export default ResultCtxProvider;
