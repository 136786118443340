import { forwardRef, useEffect, useRef, useState } from "react";
import { TTypeData } from "../../types/contexts";
import { TForm, TSearchType } from "../../types/formControl";
import * as El from "./SearchTypeElement";
import TextMessage from "./TextMessage";

type props = TSearchType & TForm;
const SearchType = forwardRef<{ focus?: () => void }, props>((props, ref) => {
  const {
    label,
    name,
    error,
    message,
    register,
    isLoading,
    setValue,
    autoComplete,
    options,
    childRef,
    defaultValue,
    ...rest
  } = props;
  const inputRef = useRef<HTMLInputElement>(document.createElement("input"));
  const [selected, setSelected] = useState<TTypeData>(null);
  useEffect(() => {
    if (options.length > 1 && selected === null) setSelected(options[0].id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  useEffect(() => {
    setValue("searchType", selected);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  useEffect(() => {
    if (defaultValue) setSelected(defaultValue);
  }, [defaultValue]);

  return (
    <El.InputContainer>
      {!!label && <El.Label htmlFor={name}>{label}</El.Label>}
      <El.InputWrapper>
        {options.map((opt, idx) => (
          <El.Option
            key={opt.value}
            isActive={selected === opt.id}
            onClick={() => setSelected(opt.id)}
          >
            {opt.value}
          </El.Option>
        ))}
      </El.InputWrapper>
      <El.InputElement
        ref={inputRef}
        {...register("searchType")}
        className={`${error && "error"}`}
        name={name}
        type="text"
        isLoading={isLoading || false}
        autoComplete={autoComplete || "on"}
        {...rest}
      />
      <div style={{ display: "flex", flexDirection: "column" }}>
        <TextMessage message={message} color="danger" />
      </div>
    </El.InputContainer>
  );
});

export default SearchType;
