import styled from "styled-components";

const Page404 = () => {
  return (
    <Main>
      <h1 className="text-404">404</h1>
      <h1 className="info">Page Not Found</h1>
    </Main>
  );
};

export default Page404;

const Main = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 16px;

  > h1.text-404 {
    font-size: 80px;
    line-height: 1;
    color: ${({ theme }) => theme.color[1]};
  }
  > h1.info {
    font-size: 24px;
    line-height: 1;
    color: ${({ theme }) => theme.color[2]};
  }
`;
