import styled, { keyframes } from "styled-components";

const LoadingData = () => {
  return (
    <Main>
      <DataSection>
        <LoadTitle className="load2" width="100%" />
        <InfoWrapper>
          <div className="text-wrapper">
            <LoadName className="load" width="200px" />
            <LoadValue className="load" width="150px" />
          </div>
          <div className="text-wrapper">
            <LoadName className="load" width="180px" />
            <LoadValue className="load" width="130px" />
          </div>
          <div className="text-wrapper">
            <LoadName className="load" width="260px" />
            <LoadValue className="load" width="200px" />
          </div>
          <div className="text-wrapper">
            <LoadName className="load" width="170px" />
            <LoadValue className="load" width="120px" />
          </div>
          <div className="text-wrapper">
            <LoadName className="load" width="150px" />
            <LoadValue className="load" width="110px" />
          </div>
        </InfoWrapper>
      </DataSection>
      <DataSection>
        <LoadTitle className="load2" width="100%" />
        <CertificateList>
          {[1, 2].map((val) => (
            <div className="item-wrapper" key={val}>
              <LoadValue className="load" width="280px" />
              <div className="cert-detail">
                <div className="text-wrapper">
                  <LoadName className="load" width="180px" />
                  <LoadValue className="load" width="130px" />
                </div>
                <div className="text-wrapper">
                  <LoadName className="load" width="150px" />
                  <LoadValue className="load" width="100px" />
                </div>
              </div>
            </div>
          ))}
        </CertificateList>
      </DataSection>
    </Main>
  );
};

export default LoadingData;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 20px;
`;

const shimmer = keyframes`0%{
  background-position: -350px 0;
}
100%{
  background-position: 350px 0;
}`;

const shimmer2 = keyframes`0%{
  background-position: -800px 0;
}
100%{
  background-position: 800px 0;
}`;

const DataSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  .load::before {
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    background-image: linear-gradient(
      to right,
      ${({ theme }) => theme.loading[1]} 0%,
      ${({ theme }) => theme.loading[2]} 20%,
      ${({ theme }) => theme.loading[1]} 40%,
      ${({ theme }) => theme.loading[1]} 100%
    );
    background-repeat: no-repeat;
    background-size: 350px 400px;
    animation: ${shimmer} 1s linear infinite;
  }
  .load2::before {
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    background-image: linear-gradient(
      to right,
      ${({ theme }) => theme.loading[1]} 0%,
      ${({ theme }) => theme.loading[2]} 20%,
      ${({ theme }) => theme.loading[1]} 40%,
      ${({ theme }) => theme.loading[1]} 100%
    );
    background-repeat: no-repeat;
    background-size: 800px 400px;
    animation: ${shimmer2} 1.5s linear infinite;
  }
`;

type TLoadValue = {
  width: string;
};

const LoadTitle = styled.div<TLoadValue>`
  display: flex;
  min-height: 32px;
  width: ${({ width }) => width};
  overflow: hidden;
  border-radius: 25px;
  position: relative;
  background: ${({ theme }) => theme.loading[1]};
  @media screen and (max-width: ${({ theme: { screen } }) => screen.sm}) {
    min-height: 28px;
  }
`;

const LoadName = styled.div<TLoadValue>`
  display: flex;
  min-height: 12px;
  width: ${({ width }) => width};
  overflow: hidden;
  border-radius: 25px;
  position: relative;
  background: ${({ theme }) => theme.loading[1]};
  @media screen and (max-width: ${({ theme: { screen } }) => screen.sm}) {
    min-height: 11px;
  }
`;
const LoadValue = styled.div<TLoadValue>`
  display: flex;
  min-height: 16px;
  border-radius: 25px;
  width: ${({ width }) => width};
  overflow: hidden;
  position: relative;
  background: ${({ theme }) => theme.loading[1]};
  @media screen and (max-width: ${({ theme: { screen } }) => screen.sm}) {
    min-height: 14px;
  }
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 15px;
  gap: 15px;
  > div.text-wrapper {
    display: flex;
    flex-direction: column;
    gap: 3px;
    > div.name {
      font-size: 12px;
      font-weight: 500;
      line-height: 1;
      color: ${({ theme }) => theme.color[2]};
      @media screen and (max-width: ${({ theme: { screen } }) => screen.sm}) {
        font-size: 11px;
      }
    }
    > div.value {
      font-size: 16px;
      font-weight: 500;
      line-height: 1;
      color: ${({ theme }) => theme.color[1]};
      @media screen and (max-width: ${({ theme: { screen } }) => screen.sm}) {
        font-size: 14px;
      }
    }
    > div.value.status {
      color: ${({ theme }) => theme.color[6]};
    }
  }
`;

const CertificateList = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 15px;
  gap: 10px;

  > div.item-wrapper {
    display: flex;
    flex-direction: column;
    padding: 10px;
    gap: 10px;
    border-radius: 3px;

    > div.cert-name {
      font-size: 15px;
      font-weight: 700;
      line-height: 1;
      color: ${({ theme }) => theme.color[8]};
      @media screen and (max-width: ${({ theme: { screen } }) => screen.sm}) {
        font-size: 14px;
      }
    }
    > div.cert-detail {
      display: flex;
      flex-direction: column;
      gap: 10px;
      > div.text-wrapper {
        display: flex;
        flex-direction: column;
        gap: 3px;
        padding-left: 15px;
        > div.name {
          font-size: 12px;
          font-weight: 500;
          line-height: 1;
          color: ${({ theme }) => theme.color[2]};
        }
        > div.value {
          font-size: 16px;
          text-transform: uppercase;
          font-weight: 500;
          line-height: 1;
          color: ${({ theme }) => theme.color[1]};
        }
        @media screen and (max-width: ${({ theme: { screen } }) => screen.sm}) {
          > div.name {
            font-size: 11px;
          }
          > div.value {
            font-size: 14px;
          }
        }
      }
    }

    transition: 0.4s all ease;
  }
  @media screen and (max-width: ${({ theme: { screen } }) => screen.sm}) {
    padding: 0 5px;
  }
`;
