import { forwardRef } from "react";
import {
  TPropsFormControl,
  TInput,
  TForm,
  TSearchType,
} from "../../types/formControl";
import Input from "./Input";
import SearchType from "./SearchType";
type TRef = { focus?: () => void; reset?: () => void };
const FormsControl = forwardRef<TRef, TPropsFormControl>((props, ref) => {
  const { control } = props;
  switch (control) {
    case "input": {
      const { ...rest } = props as TInput & TForm;
      return <Input ref={ref} {...rest} />;
    }
    case "searchType": {
      const { ...rest } = props as TSearchType & TForm;
      return <SearchType ref={ref} {...rest} />;
    }
    default:
      return null;
  }
});

export default FormsControl;
