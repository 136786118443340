import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { ResultCtx } from "../contexts/ResultCtx";

const Header = () => {
  const navigate = useNavigate();
  const { dispatch } = useContext(ResultCtx);
  return (
    <Main>
      <LogoWrapper
        onClick={() => {
          dispatch({
            type: "CLEAR_DATA",
          });
          navigate("/");
        }}
      >
        <img src="/header-logo.png" alt="logo" />
        <div className="title-wrapper">
          <h1 className="title">BP3IP Jakarta</h1>
          <h1 className="sub-title">
            Part of BPSDM Perhubungan - Kementerian Perhubungan
          </h1>
        </div>
      </LogoWrapper>
    </Main>
  );
};

export default Header;

const Main = styled.header`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 25px;
  justify-content: center;
  min-height: 64px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  @media screen and (max-width: ${({ theme: { screen } }) => screen.sm}) {
    align-items: center;
    padding: 8px;
  }
`;

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  cursor: pointer;
  > div.title-wrapper {
    display: flex;
    flex-direction: column;
  }
  > img {
    height: 50px;
    @media screen and (max-width: ${({ theme: { screen } }) => screen.sm}) {
      height: 40px;
    }
  }
  > div.title-wrapper > h1.title {
    font-weight: 600;
    font-size: 16px;
    line-height: 1;
    color: ${({ theme }) => theme.color[1]};
    @media screen and (max-width: ${({ theme: { screen } }) => screen.sm}) {
      font-size: 14px;
    }
  }
  > div.title-wrapper > h1.sub-title {
    font-weight: 500;
    font-size: 13px;
    line-height: 1;
    color: ${({ theme }) => theme.color[2]};
    @media screen and (max-width: ${({ theme: { screen } }) => screen.sm}) {
      font-size: 11px;
    }
  }
`;
