import * as yup from "yup";

export const validationSchema = yup.object({
  searchType: yup.string().required(),
  searchValue: yup
    .string()
    .required("Input tidak boleh kosong")
    .transform((value, ov) => {
      return (value as string).replaceAll(".", "");
    }),
});
