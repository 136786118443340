export const IconsControl = (name: string) => {
  switch (name) {
    case "search":
      return (
        <svg viewBox="0 0 512 512">
          <path
            d="M221.09 64a157.09 157.09 0 10157.09 157.09A157.1 157.1 0 00221.09 64z"
            fill="none"
            strokeMiterlimit="10"
          />
          <path
            fill="none"
            strokeLinecap="round"
            strokeMiterlimit="10"
            d="M338.29 338.29L448 448"
          />
        </svg>
      );
    case "text":
      return (
        <svg viewBox="0 0 512 512">
          <path
            d="M416 221.25V416a48 48 0 01-48 48H144a48 48 0 01-48-48V96a48 48 0 0148-48h98.75a32 32 0 0122.62 9.37l141.26 141.26a32 32 0 019.37 22.62z"
            fill="none"
            strokeLinejoin="round"
          />
          <path
            d="M256 56v120a32 32 0 0032 32h120M176 288h160M176 368h160"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case "check":
      return (
        <svg viewBox="0 0 20 20" stroke="currentColor">
          <path
            fillRule="evenodd"
            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
            clipRule="evenodd"
          />
        </svg>
      );
    default:
      return null;
  }
};

export default IconsControl;
