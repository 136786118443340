import { useContext, useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { ResultCtx } from "../../../contexts/ResultCtx";
import {
  getBiodataStatus,
  getHistoryStatus,
} from "../../../Services/getStatus";
import { TResResultData } from "../../../types/respons";

const DataResult = () => {
  const [data, setData] = useState<TResResultData>(null);
  const { result } = useContext(ResultCtx);

  useEffect(() => {
    if (result.data) setData(result.data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result.data, result.type]);

  return (
    <Main>
      <DataSection>
        <h1 className="name">
          Biodata Umum / <i className="text-color-eng">General Bio</i>
        </h1>
        <InfoWrapper>
          <div className="text-wrapper">
            <h1 className="name">
              Nama Lengkap / <i className="text-color-eng">Full Name</i>
            </h1>
            <h1 className="value">{`${data?.nama_depan} ${
              data?.nama_belakang || ""
            }`}</h1>
          </div>
          <div className="text-wrapper">
            <h1 className="name">
              NIK / <i className="text-color-eng">ID Number</i>
            </h1>
            <h1 className="value">{data?.nik || "-"}</h1>
          </div>
          <div className="text-wrapper">
            <h1 className="name">
              Tempat, Tgl. Lahir /{" "}
              <i className="text-color-eng">Place, Date Of Birth</i>
            </h1>
            <h1 className="value">{`${data?.kota_lahir}, ${data?.tgl_lahir}`}</h1>
          </div>
          <div className="text-wrapper">
            <h1 className="name">
              Warganegara / <i className="text-color-eng">Nationality</i>
            </h1>
            <h1 className="value">{data?.warganegara}</h1>
          </div>
          <div className="text-wrapper">
            <h1 className="name">
              Status / <i className="text-color-eng">Status</i>
            </h1>
            <StatusInfo status={data?.status}>
              {getBiodataStatus(data?.status)}
            </StatusInfo>
          </div>
        </InfoWrapper>
      </DataSection>
      <DataSection>
        <h1 className="name">
          DETAIL SERTIFIKAT /{" "}
          <i className="text-color-eng">SERTFICATE DETAIL</i>
        </h1>
        <CertificateList>
          {data?.history?.map((cert) => (
            <div className="item-wrapper" key={cert.no_sertifikat}>
              <h1 className="cert-name">{cert?.diklat}</h1>
              <div className="cert-detail">
                <div className="text-wrapper">
                  <h1 className="name">
                    No. Sertifikat /{" "}
                    <i className="text-color-eng">Certificate No</i>
                  </h1>
                  <h1 className="value">{cert?.no_sertifikat}</h1>
                </div>
                <div className="text-wrapper">
                  <h1 className="name">
                    Tempat Terbit /{" "}
                    <i className="text-color-eng">Issued Place</i>
                  </h1>
                  <h1 className="value">{cert?.tempat_terbit}</h1>
                </div>
                <div className="text-wrapper">
                  <h1 className="name">
                    Tanggal Terbit /{" "}
                    <i className="text-color-eng">Issued Place</i>
                  </h1>
                  <h1 className="value">{cert?.tanggal_terbit}</h1>
                </div>
                <div className="text-wrapper">
                  <h1 className="name">
                    Status / <i className="text-color-eng">Status</i>
                  </h1>
                  <StatusInfo status={data?.status}>
                    {getHistoryStatus(data?.status)}
                  </StatusInfo>
                </div>
              </div>
            </div>
          ))}
        </CertificateList>
      </DataSection>
    </Main>
  );
};

export default DataResult;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  padding-top: 20px;
`;

const DataSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  > h1.name {
    font-size: 18px;
    font-weight: 600;
    padding: 3px 15px;
    border-radius: 25px;
    text-transform: uppercase;
    color: ${({ theme }) => theme.color[1]};
    background: ${({ theme }) => theme.background[5]};
    @media screen and (max-width: ${({ theme: { screen } }) => screen.sm}) {
      font-size: 15px;
      text-align: center;
    }
  }

  i.text-color-eng {
    color: ${({ theme }) => theme.color[10]};
  }
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 15px;
  gap: 15px;
  > div.text-wrapper {
    display: flex;
    flex-direction: column;
    gap: 3px;
    > h1.name {
      font-size: 12px;
      font-weight: 500;
      line-height: 1;
      color: ${({ theme }) => theme.color[2]};
      @media screen and (max-width: ${({ theme: { screen } }) => screen.sm}) {
        font-size: 11px;
      }
    }
    > h1.value {
      font-size: 16px;
      font-weight: 500;
      line-height: 1;
      text-transform: uppercase;
      color: ${({ theme }) => theme.color[1]};
      @media screen and (max-width: ${({ theme: { screen } }) => screen.sm}) {
        font-size: 14px;
      }
    }
    > h1.value.status {
      color: ${({ theme }) => theme.color[6]};
    }
  }
`;

const CertificateList = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 15px;
  gap: 10px;

  > div.item-wrapper {
    display: flex;
    flex-direction: column;
    border: 1px solid ${({ theme }) => theme.background[5]};
    padding: 10px;
    gap: 10px;
    border-radius: 10px;
    cursor: pointer;
    :hover {
      background: ${({ theme }) => theme.button.section.background};
      border-color: ${({ theme }) => theme.button.primary.background};
    }

    > h1.cert-name {
      font-size: 15px;
      font-weight: 700;
      line-height: 1;
      color: ${({ theme }) => theme.color[8]};
      @media screen and (max-width: ${({ theme: { screen } }) => screen.sm}) {
        font-size: 14px;
      }
    }
    > div.cert-detail {
      display: flex;
      flex-direction: column;
      gap: 10px;
      > div.text-wrapper {
        display: flex;
        flex-direction: column;
        gap: 3px;
        padding-left: 15px;
        > h1.name {
          font-size: 12px;
          font-weight: 500;
          line-height: 1;
          color: ${({ theme }) => theme.color[2]};
        }
        > h1.value {
          font-size: 16px;
          text-transform: uppercase;
          font-weight: 500;
          line-height: 1;
          color: ${({ theme }) => theme.color[1]};
        }
        @media screen and (max-width: ${({ theme: { screen } }) => screen.sm}) {
          > h1.name {
            font-size: 11px;
          }
          > h1.value {
            font-size: 14px;
          }
        }
      }
    }

    transition: 0.4s all ease;
  }
  @media screen and (max-width: ${({ theme: { screen } }) => screen.sm}) {
    padding: 0 5px;
  }
`;

type TStatusInfo = {
  status: boolean;
};

const StatusInfo = styled.h1<TStatusInfo>`
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 500;
  line-height: 1;
  ${({ status }) =>
    !status &&
    css`
      color: ${({ theme }) => theme.color[5]};
    `}
  ${({ status }) =>
    status &&
    css`
      color: ${({ theme }) => theme.color[6]};
    `}
    @media screen and (max-width: ${({ theme: { screen } }) => screen.sm}) {
    font-size: 14px;
  }
`;
