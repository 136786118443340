import { TResultRdcr, TResultState } from "../types/contexts";
import { TResResultData } from "../types/respons";

export const reducer: TResultRdcr = (state, action) => {
  switch (action.type) {
    case "SET_DATA": {
      return {
        ...state,
        type: action.value.type,
        data: action.value.data,
        error: null,
      };
    }
    case "CLEAR_DATA": {
      return {
        ...state,
        data: null,
        searchValue: null,
        error: null,
      };
    }
    case "SET_SEARCH_TYPE": {
      return {
        ...state,
        type: action.value.type,
        searchValue: action.value.searchValue,
        data: state.data as TResResultData,
      };
    }
    case "SET_LOADING": {
      return {
        ...state,
        loading: action.value.loading,
      };
    }
    case "SET_ERROR": {
      return {
        ...state,
        error: action.value.error,
      };
    }
    default:
      return state;
  }
};

export const initialValue: TResultState = {
  type: "NIK",
  error: null,
  loading: false,
  searchValue: null,
  data: null,
};
