import { useForm } from "react-hook-form";
import styled from "styled-components";
import FormsControl from "../../Forms/FormsControl";
import { yupResolver } from "@hookform/resolvers/yup";
import { validationSchema } from "./validationScema";
import { TFormSearchData } from "../../../types/searchForm";
import Button from "../../Buttons/Button";
import { useContext, useEffect } from "react";
import { ResultCtx } from "../../../contexts/ResultCtx";
import getPlaceholder from "../../../Services/getPlaceholder";
import bp3ipApi from "../../../Services/bp3ipApi";
import { useParams } from "react-router-dom";
import { TTypeData } from "../../../types/contexts";

const SearchInput = () => {
  const { register, handleSubmit, formState, setValue, watch, resetField } =
    useForm<TFormSearchData>({
      mode: "all",
      reValidateMode: "onChange",
      defaultValues: {},
      shouldFocusError: true,
      resolver: yupResolver(validationSchema),
    });
  const { result, dispatch } = useContext(ResultCtx);
  const params = useParams();

  const { errors } = formState;
  const onSubmit = async (values: TFormSearchData) => {
    dispatch({ type: "SET_LOADING", value: { loading: true } });
    dispatch({
      type: "SET_SEARCH_TYPE",
      value: {
        type: values.searchType,
        searchValue: values.searchValue,
      },
    });
    bp3ipApi({
      type: values.searchType,
      search: values.searchValue,
    })
      .then((val) => {
        if (val?.data.length === 0) throw new Error("no data");
        dispatch({
          type: "SET_DATA",
          value: {
            type: values.searchType,
            data: val?.data[0],
          },
        });
      })
      .catch((err) =>
        dispatch({ type: "SET_ERROR", value: { error: "error fetch data" } })
      )
      .finally(() =>
        dispatch({ type: "SET_LOADING", value: { loading: false } })
      );
  };

  useEffect(() => {
    if (params?.id) {
      setValue("searchType", "CERTIFICATE_NUMBER");
      setValue("searchValue", params?.id as string);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params?.id]);

  useEffect(() => {
    const sType = watch("searchType") as TTypeData;
    if (!!sType)
      dispatch({
        type: "SET_SEARCH_TYPE",
        value: { type: sType, searchValue: watch("searchValue") },
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Main>
      <CompName>
        Cek Kredensial / <i>Check your Credentials</i>
      </CompName>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="input-wrapper">
          <div
            className="search-type"
            onClick={() => {
              resetField("searchValue");
              dispatch({ type: "CLEAR_DATA" });
            }}
          >
            <FormsControl
              control="searchType"
              name="searchType"
              setValue={setValue}
              options={[
                { id: "NIK", value: "NIK" },
                { id: "CERTIFICATE_NUMBER", value: "Certificate Number" },
              ]}
              defaultValue={result.type}
              register={register}
              error={errors.searchType ? true : false}
              disabled={result.loading}
              message={errors.searchType ? errors.searchType.message : null}
            />
          </div>
          <FormsControl
            control="input"
            type="text"
            name="searchValue"
            register={register}
            error={errors.searchValue ? true : false}
            disabled={result.loading}
            placeholder={getPlaceholder(watch("searchType"))}
            message={errors.searchValue ? errors.searchValue.message : null}
          />
        </div>
        <div className="button-wrapper">
          <Button
            type="submit"
            name="Search"
            icon="search"
            isLoading={result.loading}
          />
        </div>
      </Form>
    </Main>
  );
};

export default SearchInput;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  height: fit-content;
  border-radius: 5px;
  position: relative;
  z-index: 1;
  background: transparent;
  box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset,
    rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;

  @media screen and (max-width: ${({ theme: { screen } }) => screen.md}) {
    width: 100%;
  }
`;

const CompName = styled.h1`
  font-size: 14px;
  text-align: center;
  font-weight: 700;
  color: ${({ theme }) => theme.color[4]};
  background: ${({ theme }) => theme.background[4]};
  border-radius: 5px 5px 0 0;
  padding: 20px;
  min-width: 350px;
  @media screen and (max-width: ${({ theme: { screen } }) => screen.sm}) {
    font-size: 13px;
    min-width: auto;
  }
  transition: 0.4s all ease;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
  padding-top: 30px;
  background: ${({ theme }) => theme.background[1]};
  border-radius: 0 0 5px 5px;

  .input-wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
    > div.search-type {
      display: flex;
      width: 100%;
    }
  }
  .button-wrapper {
    display: flex;
  }
  transition: 0.4s all ease;
`;
