import axios from "axios";
import { TTypeData } from "../types/contexts";
import { TResResultData } from "../types/respons";

type TBp3ipApi = {
  type: TTypeData;
  search: string;
};

const bp3ipApi = async (p: TBp3ipApi) => {
  switch (p.type) {
    case "NIK": {
      const result = await axios.get<TResResultData[]>(
        `${process.env.REACT_APP_BACKEND_URL}/people`,
        {
          headers: {
            Authorization: `Token ${process.env.REACT_APP_TOKEN}`,
          },
          params: { search: p.search },
        }
      );
      return result;
    }
    case "CERTIFICATE_NUMBER": {
      const result = await axios.get<TResResultData[]>(
        `${process.env.REACT_APP_BACKEND_URL}/certificate`,
        {
          headers: {
            Authorization: `Token ${process.env.REACT_APP_TOKEN}`,
          },
          params: { search: p.search },
        }
      );
      return result;
    }
    default:
      return null;
  }
};

export default bp3ipApi;
