import styled, { ThemeProvider } from "styled-components";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Content from "./components/Content";
import styles from "./styles";
import ResultCtxProvider from "./contexts/ResultCtx";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import CheckCertificateParam from "./components/CheckCertificateParam";
import Page404 from "./components/Page404";

const App = () => {
  return (
    <Router>
      <ThemeProvider theme={styles}>
        <ResultCtxProvider>
          <Main>
            <Header />
            <Routes>
              <Route path="/">
                <Route index element={<Content />} />
                <Route path="v1/:id" element={<CheckCertificateParam />} />
              </Route>
              <Route path="*" element={<Page404 />} />
            </Routes>
            <Footer />
          </Main>
        </ResultCtxProvider>
      </ThemeProvider>
    </Router>
  );
};

export default App;

const Main = styled.div`
  display: grid;
  grid-template-rows: 80px 1fr 80px;
  min-height: 100vh;
`;
