const styles = {
  background: {
    1: "#eef1f7",
    2: "#fdfeff",
    3: "#dce1e6",
    4: "linear-gradient(90deg, #3494e6 0%, #ec6ead 100%);",
    5: "#c2cddf",
  },
  color: {
    1: "#2b3544",
    2: "#596577",
    3: "#d34fa2",
    4: "#ffffff",
    5: "#ff5757",
    6: "#048848",
    7: "#f7d024",
    8: "#2f5994",
    9: "#909baa",
    10: "#8e44ad",
  },
  input: {
    background: "#ffffff",
    color: "#314660",
    base: {
      background: "#fdfeff",
      color: "#314660",
      focus: {
        background: "#f4f7fa",
        color: "#314660",
      },
    },
    logo: "#738baa",
    border: "#cedbef",
    label: "#263b55",
    searchIcon: "#88a9d8",
    placeholder: "#718fba",
    borderRadius: "20px",
    dropdown: { icon: "#88a9d8", boxShadow: "0px 0px 20px -10px #263b55" },
    error: {
      background: "#ff5757",
      border: "#ff6c6c",
      color: "#ff6c6c",
      boxShadow: "0 0 0 2px #ff6c6c80",
    },
    focus: {
      background: "#ffffff",
      border: "#5f97e5",
      boxShadow: "0 0 0 2px #5c93e060",
      dropdown: { icon: "#5f97e5" },
    },
    disabled: { background: "#ecf1f6", color: "#98a9c0" },
  },
  button: {
    borderRadius: "25px",
    primary: { background: "#3f74c0", color: "#e5eaf1" },
    success: { background: "#109a57", color: "#e5eaf1" },
    danger: { background: "#fa4343", color: "#e5eaf1" },
    warning: { background: "#FCD34D", color: "#554126" },
    list: { background: "#d6e1f1", color: "#263b55" },
    base: { background: "#fdfeff", color: "#263b55" },
    section: { background: "#d3e0ee", color: "#2f5994" },
    sidebar: { background: "#c0cfe47f", color: "#263b55" },
    hover: {
      primary: { background: "#2f5994", color: "#e5eaf1" },
      success: { background: "#06733e", color: "#e5eaf1" },
      danger: { background: "#d13232", color: "#e5eaf1" },
      warning: { background: "#FBBF24", color: "#e5eaf1" },
      list: { background: "#c0cfe47f", color: "#2f5994" },
      base: { background: "#9caac576", color: "#2f5994" },
      section: { background: "#b7c9db", color: "#2f5994" },
      sidebar: { background: "#c0cfe47f", color: "#2f5994" },
    },
    focus: {
      primary: { border: "#2762b4", boxShadow: "0 0 0 3px #3f75c085" },
      success: { border: "#109a57", boxShadow: "0 0 0 3px #109a5780" },
      danger: { border: "#fa4343", boxShadow: "0 0 0 3px #fa434385" },
      warning: { border: "#fad843", boxShadow: "0 0 0 3px #fad84385" },
      list: { border: "#acbcd4", boxShadow: "0 0 0 3px #e7f0fc" },
      base: { border: "#8da9c9", boxShadow: "0 0 0 3px #c6d5e5" },
      section: { border: "#a3b5ca", boxShadow: "0 0 0 3px #c6d5e5" },
      sidebar: { background: "#c0cfe47f", color: "#3f74c0" },
    },
    disabled: { background: "#c8d3e4", color: "#ffffff" },
  },
  loading: { 1: "#c4d1e2", 2: "#8a98ac" },
  screen: { sm: "540px", md: "960px", lg: "1025px" },
};

export default styles;
