export const getBiodataStatus = (status: boolean) => {
  switch (status) {
    case false:
      return "Tidak terdaftar / Unregistered";
    case true:
      return "Terdaftar / Registered";
    default:
      return "";
  }
};

export const getHistoryStatus = (status: boolean) => {
  switch (status) {
    case false:
      return "Tidak Aktif / Inactive";
    case true:
      return "Aktif / Active";
    default:
      return "";
  }
};
