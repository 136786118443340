import { FC, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { ResultCtx } from "../contexts/ResultCtx";
import bp3ipApi from "../Services/bp3ipApi";
import Content from "./Content";

const CheckCertificateParam: FC = ({ children }) => {
  const params = useParams();
  const { dispatch } = useContext(ResultCtx);

  useEffect(() => {
    const certificateNumber = params?.id;
    if (!!certificateNumber) {
      dispatch({ type: "SET_LOADING", value: { loading: true } });
      bp3ipApi({
        type: "CERTIFICATE_NUMBER",
        search: certificateNumber,
      })
        .then((val) => {
          if (val.data.length === 0) throw new Error("no data");
          dispatch({
            type: "SET_DATA",
            value: {
              type: "CERTIFICATE_NUMBER",
              data: val.data[0],
            },
          });
        })
        .catch((err) => {
          dispatch({ type: "SET_ERROR", value: { error: "error fetch data" } });
        })
        .finally(() =>
          dispatch({ type: "SET_LOADING", value: { loading: false } })
        );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params?.id]);

  return <Content />;
};

export default CheckCertificateParam;
