import { TTypeData } from "../types/contexts";

const getPlaceholder = (status: TTypeData) => {
  switch (status) {
    case "NIK":
      return "No Induk Kependudukan / ID Number";
    case "CERTIFICATE_NUMBER":
      return "Nomor Sertifikat / Certificate Number";
    default:
      return "";
  }
};

export default getPlaceholder;
