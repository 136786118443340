import styled from "styled-components";

const Footer = () => {
  return (
    <Main>
      <h1>
        © 2022 Copyright - Balai Besar Pendidikan Penyegaran dan Peningkatan
        Ilmu Pelayaran Jakarta
      </h1>
    </Main>
  );
};

export default Footer;

const Main = styled.footer`
  display: flex;
  width: 100%;
  margin-top: 32px;
  gap: 16px;
  align-items: center;
  padding: 10px 5px;
  justify-content: center;
  background: ${({ theme }) => theme.background[3]};
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  > h1 {
    font-weight: 400;
    font-size: 14px;
    text-align: center;
    color: ${({ theme }) => theme.color[1]};
    @media screen and (max-width: ${({ theme: { screen } }) => screen.sm}) {
      font-size: 10px;
    }
  }
`;
