import { useContext, useMemo } from "react";
import styled, { css } from "styled-components";
import { ResultCtx } from "../../../contexts/ResultCtx";
import { useLocation } from "react-router-dom";
import DataResult from "./DataResult";
import LoadingData from "./LoadingData";

export const useQuery = () => {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
};

const SearchResult = () => {
  const { result } = useContext(ResultCtx);

  return (
    <Main>
      <ResultWrapper>
        <CompName>
          Hasil Pencarian / <i>Search Result</i>
        </CompName>
        <DataWrapper>
          <SearchInfo
            show={
              !!result.type &&
              !!result.searchValue &&
              !result.loading &&
              !result.error &&
              !!result.data
            }
          >
            <h1>
              {"> "}Hasil Pencarian /<i> Search Result</i> :{" "}
              {result.searchValue}
            </h1>
          </SearchInfo>
          <SearchInfo show={!result.loading && !!result.error}>
            <h1>
              {"> "}Data tidak ditemukan /<i> Data not found</i> :{" "}
              {result.searchValue}
            </h1>
          </SearchInfo>
          {result.loading && <LoadingData />}
          {!result.loading && !result.error && !!result.data && <DataResult />}
        </DataWrapper>
      </ResultWrapper>
    </Main>
  );
};

export default SearchResult;

const Main = styled.div`
  display: flex;
  width: 100%;
  border-radius: 5px;
  height: fit-content;
  box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset,
    rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
`;

const ResultWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const CompName = styled.h1`
  font-size: 14px;
  text-align: center;
  font-weight: 700;
  color: ${({ theme }) => theme.color[4]};
  background: ${({ theme }) => theme.background[4]};
  border-radius: 5px 5px 0 0;
  padding: 20px;
  @media screen and (max-width: ${({ theme: { screen } }) => screen.sm}) {
    font-size: 13px;
  }
  transition: 0.4s all ease;
`;

const DataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 400px;
  padding: 20px;
  background: ${({ theme }) => theme.background[1]};
  border-radius: 0 0 5px 5px;
  > div.motion-wrapper {
    display: flex;
    height: 100%;
    width: 100%;
  }
  > div.text-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    > svg {
      stroke: ${({ theme }) => theme.color[2]};
      opacity: 0.5;
      stroke-width: 16;
      height: 150px;
    }
  }
  transition: 0.4s all ease;
`;

type TSearchInfo = {
  show: boolean;
};

const SearchInfo = styled.div<TSearchInfo>`
  display: flex;
  max-height: 0;
  overflow: hidden;

  ${({ show }) =>
    show &&
    css`
      max-height: 64px;
    `}
  > h1 {
    text-align: justify;
    font-size: 14px;
    font-weight: 400;
    color: ${({ theme }) => theme.color[8]};
    @media screen and (max-width: ${({ theme: { screen } }) => screen.sm}) {
      font-size: 13px;
    }
  }
  transition: 0.4s all ease;
`;
