import styled, { css } from "styled-components";
import SearchResult from "./SearchResult";
import SearchInput from "./SearchInput";
import { useContext } from "react";
import { ResultCtx } from "../../contexts/ResultCtx";

const Content = () => {
  const { result } = useContext(ResultCtx);
  return (
    <Main alignItemCenter={!!result.data || !!result.error}>
      <SearchInput />
      {(!!result.data || !!result.error) && <SearchResult />}
    </Main>
  );
};

export default Content;

type TMain = {
  alignItemCenter: boolean;
};

const Main = styled.div<TMain>`
  display: flex;
  ${({ alignItemCenter }) =>
    alignItemCenter
      ? css`
          align-items: baseline;
        `
      : css`
          align-items: center;
        `}
  justify-content: center;
  padding: 20px;
  padding-top: 32px;
  gap: 32px;
  width: 100%;
  @media screen and (max-width: ${({ theme: { screen } }) => screen.md}) {
    flex-direction: column;
    padding: 20px 10px;
  }
  transition: 0.4s all ease;
  transition-property: align-items;
`;
